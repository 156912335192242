import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import Banner from "../components/Banner";
import Layout from "../components/Layout";
import PageIntroduction from "../components/PageIntroduction";
import Seo from "../components/seo";
import StoryItem from "../components/StoryItem";
import { staticImageFullHeightStyle } from "../styles";

const OurProjectsPage = () => {
  return (
    <Layout>
      <Seo
        title="Our Projects"
        description="Since 2010 the Trust has provided assistance to all kinds of schools and teaching communities to enhance facilities for children, particularly in under-privileged areas."
      />
      <Container>
        <PageIntroduction
          title="Our Projects"
          body="Since 2010 the Trust has provided assistance to all kinds of schools and teaching communities to enhance facilities for children, particularly in under-privileged areas."
        />
        <Stories />
        <LatestInitiative />
      </Container>
      <ApplicationRequestBanner />
    </Layout>
  );
};

const Stories = () => (
  <>
    <StoryItem
      title="School in Surrey"
      body={
        <>
          <p>
            The 225 computers in this school were on average 8 years old. The
            speed of the computers (or lack of it) the unreliability and lack of
            functionality was having an adverse impact on the children’s
            learning development.
          </p>
          <p>
            A severe lack of funding coupled with the burden of a deficit budget
            ruled out any major solus investment. The school was already in
            discussions with one of our partners - Funding 4 Education - to
            assess a structured leasing plan, when the Trust was approached for
            support.
          </p>
          <p>
            A grant was subsequently approved and 225 brand new PCs and monitors
            were installed at the school.
          </p>
        </>
      }
      image={
        <StaticImage
          src={"../images/computer_lab.jpeg"}
          style={staticImageFullHeightStyle}
        />
      }
      alternate
    />
    <StoryItem
      title="School & Community in York"
      body={
        <>
          <p>
            Like many schools of late, this school had to implement emergency
            remote learning facilities and lacked the budget and equipment to do
            so.
          </p>
          <p>
            With assistance from the Children’s Educational Trust, the school
            was able to deliver a program to their children to provide continued
            education through remote learning.
          </p>
        </>
      }
      image={
        <StaticImage
          src={"../images/student_remote_learning.jpeg"}
          style={staticImageFullHeightStyle}
        />
      }
    />
    <StoryItem
      title="School in Middlesex"
      body={
        <p>
          With the help of a grant award from The Children’s Educational Trust,
          this school is able to convert a previously disused old tarmac
          playground into an attractive, safe and much used play area.
        </p>
      }
      image={
        <StaticImage
          src={"../images/climbing_frame.jpg"}
          style={staticImageFullHeightStyle}
        />
      }
      alternate
    />
  </>
);

const LatestInitiative = () => {
  const Title = styled.h4`
    color: ${({ theme: { colors } }) => colors.purple};
    font-weight: 600;
    margin: 1rem 0;

    @media (min-width: 992px) {
      margin: 3rem 0;
      text-align: center;
    }
  `;

  const SubTitle = styled.h5`
    color: ${({ theme: { colors } }) => colors.purpleSecondary};
    font-weight: 600;
  `;

  const StaticImageStyle2 = {
    height: "100%",
  };

  const StyledStaticImage = styled.div`
    height: 147px;

    @media (min-width: 992px) {
      height: 300px;
    }
  `;

  const ShowcaseText = styled.p`
    text-align: center;
    margin: 1rem 0 2rem 0;

    @media (min-width: 992px) {
      margin: 1rem;
    }
  `;

  return (
    <>
      <Row>
        <Col xs="12">
          <Title>Our Latest Initiative</Title>
        </Col>
        <Col xs="12" lg="8">
          <SubTitle>The Educational Trust Grant</SubTitle>
          <p>
            The effects of Covid-19 had a global impact not least of all upon
            the structured education of children.
          </p>
          <p>
            The emergency requirement by schools to make their teaching
            environment “covid safe” dominated the school budget and plans for
            investing in improving their facilities and new equipment had to be
            placed on hold.
          </p>
          <p>
            As part of our latest initiative to help education establishments
            regain lost ground, we have made available a fund to assist schools
            to “kick start” investment into new equipment.
          </p>
          <p>
            In association with one of our Corporate partners, Funding 4
            Education, schools looking to lease any new equipment will be able
            to apply for a share of the fund (a “grant”).
          </p>
          <p>
            The fund is available for all types of equipment which may enhance
            and improve the child’s life and learning experience.
          </p>
        </Col>
        <Col xs="12" lg="4">
          <StaticImage
            src={"../images/teacher_sanitiser.jpeg"}
            style={staticImageFullHeightStyle}
          />
        </Col>
      </Row>
      <Row className="mt-lg-5">
        <Col xs="12" lg="4" className="mt-5 mt-lg-0">
          <StyledStaticImage>
            <StaticImage
              src={"../images/school_playground.jpg"}
              style={StaticImageStyle2}
            />
          </StyledStaticImage>
          <ShowcaseText>
            Primary School outdoor playground and canopy grant worth £4,000 was
            approved towards their new lease Agreement
          </ShowcaseText>
        </Col>
        <Col xs="12" lg="4">
          <StyledStaticImage>
            <StaticImage
              src={"../images/astro_turf.jpeg"}
              style={StaticImageStyle2}
            />
          </StyledStaticImage>
          <ShowcaseText>Outdoor 4G “astro turf” sports facility</ShowcaseText>
        </Col>
        <Col xs="12" lg="4">
          <StyledStaticImage>
            <StaticImage
              src={"../images/laser_cutter.jpeg"}
              style={StaticImageStyle2}
            />
          </StyledStaticImage>
          <ShowcaseText>
            We have received numerous requests for grants towards laser cutting
            equipment
          </ShowcaseText>
        </Col>
      </Row>
    </>
  );
};

const ApplicationRequestBanner = () => (
  <Banner
    text={
      <>
        <p>
          Whilst we would like to offer support to every school, the fund is
          limited so at this moment it is only being made available to the first
          three hundred schools.
        </p>
        <p>
          The Trust’s assessment panel reviews each case individually and makes
          an approval decision based on the merits of each application.
        </p>
      </>
    }
    button={{
      link: "https://funding4education.co.uk/questionnaire1/",
      text: "Click here to apply",
    }}
  />
);

export default OurProjectsPage;
