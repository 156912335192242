import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { Button, ButtonWrapper } from "../styles";

const Banner = ({ text, button }) => {
  const CTAStyles = styled.div`
    padding: 5rem 1rem;
    font-weight: bold;
    text-align: center;
    p {
      font-size: 1.2rem;
    }
  `;

  return (
    <CTAStyles>
      <Container fluid>
        <Row>
          <Col lg={{ span: "8", offset: "2" }}>
            {text}
            <ButtonWrapper>
              <Button href={button.link}>{button.text}</Button>
            </ButtonWrapper>
          </Col>
        </Row>
      </Container>
    </CTAStyles>
  );
};

export default Banner;
