import React from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";

const StoryItem = ({ title, body, image, alternate }) => {
  const order = alternate ? "first" : "last";

  return (
    <StoryItemStyles>
      <Row>
        <Col xs="12" lg="6">
          <Text>
            <Title>{title}</Title>
            <Body>{body}</Body>
          </Text>
        </Col>
        <Col xs="12" lg={{ span: "6", order: order }}>
          <ImageItem alternate={alternate}>{image}</ImageItem>
        </Col>
      </Row>
    </StoryItemStyles>
  );
};

const ImageItem = ({ alternate, children }) => {
  return (
    <>
      <AccentLarge alternate={alternate} />
      <AccentSmall alternate={alternate} />
      <StyledStaticImage>{children}</StyledStaticImage>
    </>
  );
};

const StoryItemStyles = styled.div`
  margin: 1rem 0 2rem 0;

  @media (min-width: 992px) {
    margin: initial;
  }
`;

const Text = styled.div`
  @media (min-width: 992px) {
    margin: 2rem 0;
  }
`;

const Title = styled.h3`
  background-color: ${({ theme: { colors } }) => colors.green};
  color: white;
  padding: 0.75rem;
  margin: 0;
  border-radius: 5px 5px 0 0;
  font-size: 24px;
  @media (min-width: 992px) {
    padding: 1rem 0;
    background-color: initial;
    color: ${({ theme: { colors } }) => colors.purpleSecondary};
    border-radius: initial;
    font-weight: 600;
  }
`;

const Body = styled.div`
  padding: 1rem 1rem 0 1rem;
  border: solid 1px ${({ theme: { colors } }) => colors.green};
  border-bottom: solid 1px white;
  @media (min-width: 992px) {
    padding: initial;
    border: initial;
  }
`;

const AccentLarge = styled.div`
  @media (min-width: 992px) {
    background-color: ${(props) =>
      props.alternate ? props.theme.colors.purple : props.theme.colors.pink};
    position: absolute;
    right: ${(props) => (props.alternate ? "initial" : "15px")};
    width: 70%;
    height: 70%;
  }
`;

const AccentSmall = styled.div`
  @media (min-width: 992px) {
    background-color: ${(props) =>
      props.alternate ? props.theme.colors.green : props.theme.colors.yellow};
    position: absolute;
    width: 20%;
    height: 20%;
    bottom: 5px;
    right: ${(props) => (props.alternate ? "20px" : "initial")};
  }
`;

const StyledStaticImage = styled.div`
  height: 147px;

  @media (min-width: 992px) {
    height: 100%;
    padding: 1.5rem;
  }
`;

export default StoryItem;
